import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container, Divider, Flex, Text, Button } from '@chakra-ui/react'
import QRCode from 'react-qr-code'
import { StaticImage } from 'gatsby-plugin-image'
import OKSvg from '../../icons/ok-pink.svg'
import LightNote2 from '../../icons/light-note-2.svg'
import BackSVG from '../../icons/back.svg'

// import WhiteNote from '../../icons/white-note.svg'

import { useLocation } from '@reach/router'
import { useContext } from 'react'
import { WindowHeightContext } from '../../context/windowHeightContext'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'
import {
	getRFID,
	getTempGroup,
	getTempTeam,
	setEventId
} from '../../helpers/database'
import { navigate } from 'gatsby'
import Modal from '../../components/modal'

const ReadyToPlay = ({ parent }) => {
	const height = useContext(WindowHeightContext)
	const group = getTempGroup()
	// const team = getTempTeam()
	const rfid = getRFID()
	const { resetInVenueOnboarding } = useAuth()

	const bottomFrame = (
		<StaticImage
			style={{
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 1,
				aspectRatio: 375 / 173
			}}
			alt="Bottom Banner"
			src="../images/bottom-frame-2.png"
		/>
	)

	return (
		<Container
			actualheight={height}
			variant="light"
			pb="90px"
			pt="90px"
			position="relative"
		>
			<Flex flex="1" direction="column" pt={{ lg: '60px', base: '10px' }}>
				<Flex
					zIndex="3"
					minH="48px"
					px={'20px'}
					justifyContent="space-between"
					style={{
						position: 'fixed',
						left: '60px',
						right: '60px',
						top: '60px',
						zIndex: 4
					}}
				>
					<Button
						variant="icon"
						onClick={() => navigate(-1)}
						minW="48px"
						minH="48px"
					>
						<BackSVG />
					</Button>

					<Button variant={'medium'} onClick={resetInVenueOnboarding}>
						Register New Group
					</Button>
				</Flex>
				<Flex>
					<Flex
						flex="1"
						alignItems="center"
						justifyContent="center"
						direction="column"
						ml="48px"
						mt="10"
					>
						<OKSvg fill="red" />
						<Text as="h1" variant="title" mb="7px">
							Ready to Play!
						</Text>
					</Flex>
				</Flex>

				<Flex
					mt={2}
					mb={2}
					mx="auto"
					flexDirection="column"
					p={10}
					style={{
						width: '100%',
						maxWidth: '420px',
						minHeight: '130px',
						position: 'relative'
					}}
				>
					<Flex justifyContent="space-between" py={1}>
						<Text variant="lg" fontWeight={500}>
							Group
						</Text>
						<Text variant="lg" fontWeight={800}>
							{group?.name}
						</Text>
					</Flex>
					{/* <Flex justifyContent="space-between" py={1}>
						<Text variant="lg" fontWeight={500}>
							Team
						</Text>
						<Text variant="lg" fontWeight={800}>
							{team?.name}
						</Text>
					</Flex> */}
					<Flex justifyContent="space-between" py={1}>
						<Text variant="lg" fontWeight={500}>
							RFID
						</Text>
						<Text variant="lg">{rfid?.shortCode}</Text>
					</Flex>
					<LightNote2
						style={{
							position: 'absolute',
							bottom: 0,
							left: 0,
							right: 0,
							width: '100%',
							height: '100%'
						}}
					/>
				</Flex>

				<Flex
					my={5}
					mx="auto"
					flexDirection="column"
					p={10}
					style={{
						width: '100%',
						maxWidth: '420px',
						minHeight: '130px',
						position: 'relative',
						backgroundImage: `url(/images/white-note.svg)`,
						backgroundSize: '100% 100%',
						zIndex: 10
					}}
				>
					<Flex zIndex={1} direction={'column'} alignItems="center">
						<Text
							as="h2"
							variant="subtitle"
							mb="7px"
							textTransform={'uppercase'}
							color={'#000'}
						>
							Add more players to this group
						</Text>
						<Text textAlign={'center'} mb={4}>
							Playing in the same group? Scan the QR code below to
							join the group from a phone.
						</Text>

						<QRCode
							value={`${window.location.origin}/groups/${group?.id}?autojoin=true`}
							size={130}
						/>
						<Text
							variant="lg"
							mt={3}
							mb={3}
							textTransform={'uppercase'}
							color={'#000'}
						>
							OR
						</Text>

						<Button
							variant="medium"
							style={{ color: 'white', backgroundColor: 'black' }}
							onClick={() => {
								setEventId(group.eventId)
								resetInVenueOnboarding(false)
							}}
							type="submit"
						>
							Join Here
						</Button>
					</Flex>
				</Flex>

				<StaticImage
					style={{
						position: 'absolute',
						top: 140,
						right: 0,
						height: 100,
						zIndex: 3,
						width: 60
					}}
					alt="Right Frame"
					src="../images/right_frame.png"
				/>
				<StaticImage
					style={{
						position: 'absolute',
						top: 318,
						left: 0,
						height: 174,
						zIndex: 2,
						width: 76
					}}
					alt="Left Frame"
					src="../images/left_frame.png"
				/>
				{bottomFrame}

				<Divider h="60px" opacity="0" />
			</Flex>
		</Container>
	)
}

export default ReadyToPlay
