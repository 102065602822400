import * as React from 'react'
import TimeoutModal from '../../components/timeoutModal'
import ReadyToPlay from '../../pagesComponents/login/ready-to-play'

import { useAuth } from '../../hooks/useAuth'
import Layout from '../../layout'

const ReadyToPlayPage = () => {
	const { resetInVenueOnboarding } = useAuth()

	return (
		<Layout theme="light" title="Account Created">
			<ReadyToPlay inVenue basePath="/in-venue-onboarding" />
			<TimeoutModal onReset={resetInVenueOnboarding} />
		</Layout>
	)
}

export default ReadyToPlayPage
